// ** React Imports
import React from 'react'
import { Navigate, redirect } from 'react-router-dom'

// ** Authentifications
import Login from 'pages/auth'
import PrivacyPolicy from 'pages/privacy-policy'

// Users
import UserManagement from 'pages/users'
import UserProfile from 'pages/users/containers/UserProfile'

// Groups
import GroupsManagement from 'pages/directory'
import GroupProfile from 'pages/directory/containers/GroupDetails'
import GroupsTemplateTable from 'pages/directory/containers/GroupTemplateTable'
import GroupTemplateDetails from 'pages/directory/containers/GroupTemplateDetails'

// Tools
import SignatureTemplate from 'pages/tools/signature-template/SignatureTemplate'
import TemplatesTable from 'pages/tools/signature-template/containers/TemplatesTable'

// Shared Drive
import SharedDrive from 'pages/shared-drive'

// Licenses
// import Licenses from "pages/licenses"

// Bulk Signature
import BulkSignature from 'pages/tools/bulk-signature'
import BulkSignatureFormContainer from 'pages/tools/bulk-signature/containers/BulkSignatureFormContainer'
import Editor from 'pages/tools/signature-template/containers/Editor'

import CasesTable from 'pages/support-case/containers/CasesTable'
import AddCases from 'pages/support-case/containers/addCases'
import Case from 'pages/support-case/containers/case'

const authProtectedRoutes = [
  { path: '/user-profile/:id', component: UserProfile },
  { path: '/user-management', component: UserManagement },
  { path: '/groups-management', component: GroupsManagement },
  { path: '/groups-template', component: GroupsTemplateTable },
  { path: '/groups-management/group-details/:id', component: GroupProfile },
  { path: '/groups-template/:id', component: GroupTemplateDetails },
  { path: '/tools/create-template', component: SignatureTemplate },
  { path: '/tools/editor-template', component: Editor },
  { path: '/tools/editor-template/update/:id', component: Editor },
  { path: '/tools/update-template/:id', component: SignatureTemplate },
  { path: '/tools/templates', component: TemplatesTable },
  { path: '/tools/bulk-signatures', component: BulkSignature },
  { path: '/tools/bulk-signatures/update', component: BulkSignatureFormContainer },
  { path: '/shared-drive', component: SharedDrive },
  { path: '/', exact: true, component: () => <Navigate to='/user-management' /> },
  { path: '*', component: () => <Navigate to='/user-management' /> },
  { path: '/support-case/all', component: CasesTable },
  { path: '/support-case/add', component: AddCases },
  { path: '/support-case/case/:id', component: Case }
]

const publicRoutes = [
  { path: '/login', component: Login },
  { path: '/privacy-policy', component: PrivacyPolicy }
]

export { authProtectedRoutes, publicRoutes }
