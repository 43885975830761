// ** React Imports
import React, { useState, useEffect } from 'react'

// ** Redux Imports
import { useDispatch, useSelector } from 'react-redux'

// ** APIs
import { getAllSharedDrives } from 'configs/axios/api_helper'

// ** MUI Imports
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import Divider from '@mui/material/Divider'

// ** Component Imports
import ActionsButton from '../components/buttons/ActionsButton'
import CustomTable from '../components/table/CustomTable'
import GoogleSpinner from 'core/components/google-spinner'
import CreateDrive from '../components/dialog/CreateDrive'
import AlertMessage from '../components/alert/CustomAlertMessage'

// ** Hooks
import { useAuth } from 'hooks/useAuth'



// Styled Divider component
const StyledDivider = styled(Divider)(({ theme }) => ({
    borderBottomWidth: 1,
    color: theme.palette.grey[300],
}))


const SharedDriveTable = () => {


    // ** Hooks
    const dispatch = useDispatch()
    const { user } = useAuth()

    // ** State
    const [loading, setLoading] = useState(false)
    const [sharedDrives, setSharedDrives] = useState([])
    const [message, setMessage] = useState({ success: false, error: false, value: "" })
    //const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(25)
    const [query, setQuery] = useState("")

    // ** Selectors

    // ** Fetch users 
    useEffect(() => {
        const fetchSharedDrive = async () => {
            setLoading(true)
            try {
                const response = await getAllSharedDrives(user.id, rowsPerPage, query)
                const { data: { drives } } = response
                setSharedDrives(drives)
                setLoading(false)
            } catch (error) {
                setLoading(false)
            }
        }
        fetchSharedDrive()
    }, [])

    // ** Functions
    const handleCloseMessage = () => {
        setMessage({ success: false, error: false, value: "" })
    }
    // const handleChangeRowsPerPage = (event) => {
    //     setRowsPerPage(parseInt(event.target.value, 10))
    //     setPage(0)
    // }
    // const handleChangePage = (event, newPage) => {
    //     setPage(newPage)
    // }


    return (
        <Card
            sx={{
                '&.MuiPaper-root': {
                    borderRadius: "0px !important",
                    boxShadow: 0,
                    border: theme => `1px solid ${theme.palette.grey[300]}`
                },
                '& .customer-column-header': {
                    backgroundColor: 'common.white',
                    '& .MuiDataGrid-columnHeaderTitle': {
                        fontWeight: 600
                    }
                },
            }}>
            <CardHeader
                sx={{ mb: "-1rem" }}
                title={<Typography>Shared Drives</Typography>}
                action={<CreateDrive setSharedDrives={setSharedDrives} setMessage={setMessage} />}
            />
            <StyledDivider />
            <CustomTable
                data={sharedDrives}
                columns={["Drive name", "Creacted on", "Action"]}
                loading={loading}
                path={"/tools/templates"}
                setMessage={setMessage}
                setSharedDrives={setSharedDrives}
            />
            <AlertMessage
                message={message}
                handleCloseMessage={handleCloseMessage}
            />
        </Card>


    )
}

export default SharedDriveTable
